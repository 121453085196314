import * as axios from 'axios';
// import AddReview from 'components/pages/ProductDetailsPage/sections/AddReview';
// import { updatePhoto } from 'redux/actions/gallery';
// import { faSignLanguage } from '@fortawesome/free-solid-svg-icons';
// import { getUser } from 'redux/actions/user';

export const instance = axios.create({
  // baseURL: 'http://localhost:9000/api/',
  baseURL: 'https://www.kovilook.com/api/',
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Credentials': 'true',
  },
});

export const searchAPI = {
  async getSearchResults(query, lang, page) {
    const res = await instance.get(`item?title=${query}&local=${lang}&page=${page}`);
    return res.data;
  },
};

export const itemAPI = {
  async getDataItem(productId) {
    const res = await instance.get(`item?titleUnique=${productId}`);
    return res.data.docs;
  },
  async addItem(newData) {
    const res = await instance.post(`item/single`, newData);
    return res.data;
  },
  async updateItem(_id, newData) {
    // const json = JSON.stringify(newData);
    return await instance.put(`item/single?id=${_id}`, newData, {
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': 'include',
      },
    });
  },
  // async editCall(id, status) {
  //   const formData = new FormData();
  //   // for (const key in editedReview) {
  //   //   formData.append(key, editedReview[key]);
  //   // }
  //   formData.append('status', status);
  //   return await instance.put(`call/single?id=${id}`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   });
  // },
  async setPreview(id, photo) {
    let formData = new FormData();
    formData.append('photo', photo);

    return await instance.put(`item/single/preview?id=${id}`, formData);
  },
  async deletePreview(id) {
    return await instance.delete(`item/single/preview?id=${id}`);
  },
};
function addPhotoQuality(formData) {
  const photo = formData.get('photo');
  if (photo.size > 7502975) formData.append('quality', 45);
  else if (photo.size > 5502975) formData.append('quality', 50);
  else if (photo.size > 2502975) formData.append('quality', 60);
  else if (photo.size > 1000975) formData.append('quality', 65);
  else if (photo.size > 502975) formData.append('quality', 75);
  else if (photo.size > 102975) formData.append('quality', 80);
  else if (photo.size > 52975) formData.append('quality', 85);
  else if (photo.size < 30000) formData.append('quality', 100);
  return formData;
}

export const galleryAPI = {
  async getPhoto() {
    const res = await instance.get(`gallery`);
    return res.data;
  },

  async getPhotoAdditional() {
    const res = await instance.get(`gallery/additional`);
    return res.data;
  },

  async addPhoto(newPhoto) {
    let formData = new FormData();
    for (const key in newPhoto) {
      formData.append(key, newPhoto[key]);
    }

    formData = addPhotoQuality(formData);

    return await instance.post('gallery/single', formData);
  },

  async updatePhoto(newPhoto) {
    let formData = new FormData();
    for (const key in newPhoto) {
      formData.append(key, newPhoto[key]);
    }

    formData = addPhotoQuality(formData);

    return await instance.post('gallery/update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async deletePhoto(id) {
    return await instance.delete(`gallery/single?id=${id}`);
  },

  async updateItem(_id, newData) {
    return await instance.put(`item/single`, newData, {
      headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': 'include',
      },
    });
  },
};

export const mainInfoAPI = {
  async getMainInfo() {
    const res = await instance.get('info');
    return res.data;
  },

  async addMainInfo(mainInfo) {
    let formData = new FormData();
    for (const key in mainInfo) {
      formData.append(key, mainInfo[key]);
    }
    formData.append('photoSize', '1280x758');
    formData = addPhotoQuality(formData);

    return await instance.post('info', formData, {
      headers: {
        'Content-Type': 'info',
      },
    });
  },

  async editMainInfo(newMainInfo) {
    let formData = new FormData();
    for (const key in newMainInfo) {
      formData.append(key, newMainInfo[key]);
    }
    formData.append('photoSize', '1280x1516');

    return await instance.post(`info/update`, formData);
  },
};

export const loginAPI = {
  async logout() {
    const res = await instance.get('user/logout');
    return res.data;
  },
  async login(info) {
    const formData = new FormData();
    for (const key in info) {
      formData.append(key, info[key]);
    }
    return await instance.post(`user/login`, formData, {
      headers: {
        'Access-Control-Allow-Origin': 'include',
      },
    });
  },
  async getUser() {
    const user = await instance.get('user/single');
    return user.data;
  },

  async registerUser(dataUser) {
    const formData = new FormData();
    formData.append('role', 'user');
    for (const key in dataUser) {
      formData.append(key, dataUser[key]);
    }
    return await instance.post(`user/single`, formData, {
      headers: {
        'Access-Control-Allow-Origin': 'include',
      },
    });
  },
};

export const categoryAPI = {
  async getCategories() {
    const res = await instance.get('category');
    return res.data;
  },

  async addCategory(newCategory) {
    const res = await instance.post('category/single', newCategory);
    return res.data;
  },

  async addSubCategory(id, newSubCategory) {
    const res = await instance.post(`subCategory/single?_id=${id}`, newSubCategory);
    return res.data;
  },

  async deleteCategory(id) {
    const res = await instance.delete(`category/single?id=${id}`);
    return res.data;
  },

  async deleteSubCategory(catId, subCatId) {
    const res = await instance.delete(`subCategory/single?catId=${catId}&subCatId=${subCatId}`);
    return res.data;
  },

  async editCategory(id, newCategory) {
    const res = await instance.put(`category/single?id=${id}`, newCategory);
    return res.data;
  },

  async editSubCategory(catId, id, newSubCategory) {
    const res = await instance.put(`subCategory/single?catId=${catId}&_id=${id}`, newSubCategory);
    return res.data;
  },
};

export const productsItemAPI = {
  async fetchProducts(categoryId, subCategoryId, page, sortOrder, limit = 12, statusItems) {
    const params = {
      category: categoryId,
      subCategory: subCategoryId,
      sort: sortOrder === 0 ? 'order' : 'price',
    };
    if (page) params.page = page;
    if (sortOrder) params.sortOrder = sortOrder;
    if (limit) params.limit = limit;
    if (statusItems) params.statusItems = statusItems;
    const res = await instance.get('item', {
      params,
    });
    return res.data;
  },

  async deleteProduct(id, categoryId, subCategoryId, page, sortOrder) {
    const res = await instance.delete(`item/single?id=${id}`, categoryId, subCategoryId, page, sortOrder);
    return res.data;
  },

  async fetchPopularProducts(page) {
    const res = await instance.get(`item?isPopular=${true}&page=${page}`);
    return res.data;
  },

  async addToTopSales(id, newData) {
    const res = await instance.put(`item/single?id=${id}`, newData);
    return res.data;
  },
};

export const itemReviewAPI = {
  async getReview() {
    const res = await instance.get('reviewitem');
    return res.data;
  },

  async addReview(newData) {
    const formData = new FormData();
    for (const key in newData) {
      formData.append(key, newData[key]);
    }
    return await instance.post('reviewitem/single', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async updateReview(id, newData) {
    const res = await instance.put(`reviewitem/single?id=${id}`, newData);
    return res.data;
  },

  async deleteReview(id) {
    const res = await instance.delete(`reviewitem/single?id=${id}`);
    return res.data;
  },
};

export const cartAPI = async fullOrder => {
  const res = await instance.post(`sendOrder`, fullOrder);
  return res.data.status;
};
